<template>
  <img style="visibility: hidden;" class="logo" src="./assets/logo.webp" alt="Enigma Trainer Logo" width="500" />
  <HelloWorld />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 0;
    padding: 0;
    margin: 0;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 55%;
}

html, body {
    width: 100%;
    height: 100%;
}

body {
    font-size: 2rem;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    background-color: black;
    color: #fff;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background-color: black;
  height: 100vh;
  overflow: hidden;
}

.logo {
  max-width: 100%;
  height: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media (max-width: 767px) {
	.logo {
		width: 300px;
	}
}

@media (max-height: 768px) {
	.logo {
		width: 200px;
	}
}
</style>
